import React, { FC, ReactNode } from 'react';
import { Menu as NavigationMenu } from 'react-admin';
import {
    MultiLevelMenu,
    MenuItemList,
    MenuItemCategory,
    MenuItemNode,
} from '@react-admin/ra-navigation';

import AssignmentIcon from '@mui/icons-material/Assignment';
import SettingsIcon from '@mui/icons-material/Settings';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import {
    exanteInstrumentInfo,
    exanteInstruments,
    exanteDonorMonitor,
} from '../instruments/exante';
import {
    finamInstrumentInfo,
    finamInstruments,
    finamDonorMonitor,
} from '../instruments/finam';
import {
    binanceInstrumentInfo,
    binanceInstruments,
} from '../instruments/binance';
import {
    refinitivInstruments,
    refinitivDonorMonitor,
} from '../instruments/refinitiv';
import { pl_emergency_brake } from '../pl_emergency_brake';
import manifests from '../manifests';
import {
    historyFetcherInstruments,
    historyFetcherTasks,
} from '../history_fetcher';
import { cqgInstrumentInfo } from '../instruments/cqg';
import instrument_mapping from '../instrument_mapping';
import { dagsterQuotes } from '../dagster_quotes';
import actual_contracts from '../actual_contracts';
import all_contracts from '../all_contracts';
import {
    rapid_portfolio,
    rapid_portfolio_launch,
    rapid_trader,
    rapid_portfolio_launch_schedule,
    rapid_portfolio_launch_config,
    active_rapid_trader_monitor,
} from '../rapid_portfolio';
import docker_tags from '../docker_tags';
import summary_report from '../summary_report';
import instrument_mapping_errors from '../instrument_mapping_errors';
import AppsIcon from '@mui/icons-material/Apps';
import fund_movements from '../fund_movements';
import subaccounts from '../subaccounts';
import portfolio_pl from '../portfolio_pl';
import subaccount_pl from '../subaccount_pl';
import catalogs from '../catalogs';
import instrument_group from '../catalogs/instrument_group';
import calendar_events from '../catalogs/calendar_events';
import market_data_schedule from '../catalogs/market_data_schedule';
import { oms_trade } from '../oms_trade';

interface Props {
    logout?: ReactNode;
    onMenuClick?: () => void;
}
import { CardContent, Box, Typography } from '@mui/material';
import { cmeInstruments } from '../instruments/cme';
import subaccount_snapshot_report from '../subaccount_snapshot_report';
import Accounts from '../accounts';
import Rapid_trader_notifications_settings from '../rapid_trader_notifications_settings';

const Menu = () => {
    return <DesktopMenu />;
};

export default Menu;

const DesktopMenu: FC<Props> = ({ onMenuClick }) => {
    return (
        <MultiLevelMenu variant="categories" initialOpen={false}>
            <MenuItemCategory
                name="rapid_portfolio_menu"
                icon={<rapid_portfolio.icon />}
                onClick={onMenuClick}
                label="Rapid Portfolio"
            >
                <CardContent>
                    <Box display="flex" justifyContent="space-between">
                        <Box marginRight={2}>
                            <NavigationMenu>
                                <MenuItemNode
                                    name="rapid_portfolio"
                                    to="/rapid_portfolio"
                                    icon={<rapid_portfolio.icon />}
                                    onClick={onMenuClick}
                                    label="Rapid portfolios"
                                />
                                <MenuItemNode
                                    name="rapid_portfolio_launch"
                                    to="/rapid_portfolio_launch"
                                    icon={<rapid_portfolio_launch.icon />}
                                    onClick={onMenuClick}
                                    label="Rapid portfolio launches"
                                />
                                <MenuItemNode
                                    name="rapid_trader"
                                    to="/rapid_trader"
                                    icon={<rapid_trader.icon />}
                                    onClick={onMenuClick}
                                    label="Rapid traders"
                                />
                                <MenuItemNode
                                    name="rapid_portfolio_launch_schedule"
                                    to="/rapid_portfolio_launch_schedule"
                                    icon={
                                        <rapid_portfolio_launch_schedule.icon />
                                    }
                                    onClick={onMenuClick}
                                    label="Rapid Schedule"
                                />
                                <MenuItemNode
                                    name="rapid_portfolio_launch_config"
                                    to="/rapid_portfolio_launch_config"
                                    icon={
                                        <rapid_portfolio_launch_config.icon />
                                    }
                                    onClick={onMenuClick}
                                    label="Rapid Portfolio Launch Config"
                                />
                                <MenuItemNode
                                    name="active_rapid_trader_monitor"
                                    to="/active_rapid_trader_monitor"
                                    icon={<active_rapid_trader_monitor.icon />}
                                    onClick={onMenuClick}
                                    label="Active Rapid Trader Monitor"
                                />
                                <MenuItemNode
                                    name="rapid_trader_notifications_settings"
                                    to="/rapid_trader_notifications_settings"
                                    icon={
                                        <Rapid_trader_notifications_settings.icon />
                                    }
                                    onClick={onMenuClick}
                                    label="Rapid Trader Notification Settings"
                                />
                            </NavigationMenu>
                        </Box>
                    </Box>
                </CardContent>
            </MenuItemCategory>
            <MenuItemCategory
                name="reports"
                icon={<AssignmentIcon />}
                onClick={onMenuClick}
                label="Reports"
            >
                <CardContent>
                    <Box display="flex" justifyContent="space-between">
                        <Box marginRight={2}>
                            <NavigationMenu>
                                <MenuItemNode
                                    name="summary_report"
                                    to="/summary_report"
                                    icon={<summary_report.icon />}
                                    onClick={onMenuClick}
                                    label="Summary Report"
                                />
                                <MenuItemNode
                                    name="portfolio_pl"
                                    to="/portfolio_pl"
                                    icon={<portfolio_pl.icon />}
                                    onClick={onMenuClick}
                                    label="Portfolio PL Report"
                                />
                                <MenuItemNode
                                    name="subaccount_pl"
                                    to="/subaccount_pl"
                                    icon={<subaccount_pl.icon />}
                                    onClick={onMenuClick}
                                    label="Subaccount PL Report"
                                />
                                <MenuItemNode
                                    name="instrument_mapping_errors"
                                    to="/instrument_mapping_errors"
                                    icon={<instrument_mapping_errors.icon />}
                                    onClick={onMenuClick}
                                    label="Instrument mapping errors"
                                />
                                <MenuItemNode
                                    name="oms_trade"
                                    to="/oms_trade"
                                    icon={<oms_trade.icon />}
                                    onClick={onMenuClick}
                                    label="OMS Trades"
                                />
                                <MenuItemNode
                                    name="subaccount_snapshot"
                                    to="/subaccount_snapshot"
                                    icon={<subaccount_snapshot_report.icon />}
                                    onClick={onMenuClick}
                                    label="Subaccount Snapshot Report"
                                />
                            </NavigationMenu>
                        </Box>
                    </Box>
                </CardContent>
            </MenuItemCategory>
            <MenuItemCategory
                name="mappings"
                icon={<instrument_mapping.icon />}
                onClick={onMenuClick}
                label="Mappings"
            >
                <CardContent>
                    <Box display="flex" justifyContent="space-between">
                        <Box marginRight={2}>
                            <NavigationMenu>
                                <MenuItemNode
                                    name="actual_contracts"
                                    to="/actual_contracts"
                                    icon={<actual_contracts.icon />}
                                    onClick={onMenuClick}
                                    label="Actual contracts"
                                />
                                <MenuItemNode
                                    name="all_contracts"
                                    to="/all_contracts"
                                    icon={<all_contracts.icon />}
                                    onClick={onMenuClick}
                                    label="All contracts"
                                />
                                <MenuItemNode
                                    name="instrument_mapping"
                                    to="/instrument_mapping"
                                    icon={<instrument_mapping.icon />}
                                    onClick={onMenuClick}
                                    label="Instrument mapping"
                                />
                            </NavigationMenu>
                        </Box>
                    </Box>
                </CardContent>
            </MenuItemCategory>
            <MenuItemCategory
                name="data"
                icon={<AppsIcon />}
                onClick={onMenuClick}
                label="Data"
            >
                <CardContent>
                    <Box display="flex" justifyContent="space-between">
                        <Box marginRight={2}>
                            <Typography variant="h6" gutterBottom>
                                {'Exante'}
                            </Typography>
                            <MenuItemList>
                                <MenuItemNode
                                    name="exante_instruments"
                                    to="/exante_instruments"
                                    icon={<exanteInstruments.icon />}
                                    onClick={onMenuClick}
                                    label="Donor Subscription"
                                />
                                <MenuItemNode
                                    name="exante_instrument_details"
                                    to="/exante_instrument_details"
                                    icon={<exanteInstrumentInfo.icon />}
                                    onClick={onMenuClick}
                                    label="Instrument details"
                                />
                                <MenuItemNode
                                    name="exante_donor_monitor"
                                    to="/exante_donor_monitor"
                                    icon={<exanteDonorMonitor.icon />}
                                    onClick={onMenuClick}
                                    label="Donor Monitor"
                                />
                            </MenuItemList>
                            <Typography variant="h6" gutterBottom>
                                {'Finam'}
                            </Typography>
                            <MenuItemList>
                                <MenuItemNode
                                    name="finam_instruments"
                                    to="/finam_instruments"
                                    icon={<finamInstruments.icon />}
                                    onClick={onMenuClick}
                                    label="Donor Subscription"
                                />
                                <MenuItemNode
                                    name="finam_instrument_details"
                                    to="/finam_instrument_details"
                                    icon={<finamInstrumentInfo.icon />}
                                    onClick={onMenuClick}
                                    label="Instrument details"
                                />
                                <MenuItemNode
                                    name="finam_donor_monitor"
                                    to="/finam_donor_monitor"
                                    icon={<finamDonorMonitor.icon />}
                                    onClick={onMenuClick}
                                    label="Donor Monitor"
                                />
                            </MenuItemList>
                            <Typography variant="h6" gutterBottom>
                                {'Binance'}
                            </Typography>
                            <MenuItemList>
                                <MenuItemNode
                                    name="binance_instruments"
                                    to="/binance_instruments"
                                    icon={<binanceInstruments.icon />}
                                    onClick={onMenuClick}
                                    label="Donor Subscription"
                                />
                                <MenuItemNode
                                    name="binance_instrument_details"
                                    to="/binance_instrument_details"
                                    icon={<binanceInstrumentInfo.icon />}
                                    onClick={onMenuClick}
                                    label="Instrument details"
                                />
                            </MenuItemList>
                            <Typography variant="h6" gutterBottom>
                                {'CQG Broker'}
                            </Typography>
                            <MenuItemList>
                                <MenuItemNode
                                    name="cqg_instrument_info"
                                    to="/cqg_instrument_info"
                                    icon={<cqgInstrumentInfo.icon />}
                                    onClick={onMenuClick}
                                    label="Instrument details"
                                />
                            </MenuItemList>
                            <Typography variant="h6" gutterBottom>
                                {'Refinitiv'}
                            </Typography>
                            <MenuItemList>
                                <MenuItemNode
                                    name="refinitiv_instruments"
                                    to="/refinitiv_instruments"
                                    icon={<refinitivInstruments.icon />}
                                    onClick={onMenuClick}
                                    label="Donor Subscription"
                                />
                                <MenuItemNode
                                    name="refinitiv_donor_monitor"
                                    to="/refinitiv_donor_monitor"
                                    icon={<refinitivDonorMonitor.icon />}
                                    onClick={onMenuClick}
                                    label="Donor Monitor"
                                />
                            </MenuItemList>
                            <Typography variant="h6" gutterBottom>
                                {'CME'}
                            </Typography>
                            <MenuItemList>
                                <MenuItemNode
                                    name="cme_instruments"
                                    to="/cme_instruments"
                                    icon={<cmeInstruments.icon />}
                                    onClick={onMenuClick}
                                    label="Donor Subscription"
                                />
                            </MenuItemList>
                            <MenuItemList>
                                <Typography variant="h6" gutterBottom>
                                    {'Historic'}
                                </Typography>
                                <MenuItemNode
                                    name="history_fetcher_instruments"
                                    to="/history_fetcher_instruments"
                                    icon={<historyFetcherInstruments.icon />}
                                    onClick={onMenuClick}
                                    label="Instruments"
                                />
                                <MenuItemNode
                                    name="history_fetcher_tasks"
                                    to="/history_fetcher_tasks"
                                    icon={<historyFetcherTasks.icon />}
                                    onClick={onMenuClick}
                                    label="Download Tasks"
                                />
                            </MenuItemList>
                        </Box>
                    </Box>
                </CardContent>
            </MenuItemCategory>
            <MenuItemCategory
                name="accounting"
                icon={<AccountBalanceIcon />}
                onClick={onMenuClick}
                label="Accounting"
            >
                <CardContent>
                    <Box display="flex" justifyContent="space-between">
                        <Box marginRight={2}>
                            <NavigationMenu>
                                <MenuItemNode
                                    name="fund_movements"
                                    to="/fund_movements"
                                    icon={<fund_movements.icon />}
                                    onClick={onMenuClick}
                                    label="Fund movements"
                                />
                                <MenuItemNode
                                    name="Accounts"
                                    to="/accounts"
                                    icon={<Accounts.icon />}
                                    onClick={onMenuClick}
                                    label="Accounts"
                                />
                                <MenuItemNode
                                    name="Subaccounts"
                                    to="/subaccounts"
                                    icon={<subaccounts.icon />}
                                    onClick={onMenuClick}
                                    label="Subaccounts"
                                />
                            </NavigationMenu>
                        </Box>
                    </Box>
                </CardContent>
            </MenuItemCategory>
            <MenuItemCategory
                name="settings"
                icon={<SettingsIcon />}
                onClick={onMenuClick}
                label="Settings"
            >
                <CardContent>
                    <Box display="flex" justifyContent="space-between">
                        <Box marginRight={2}>
                            <NavigationMenu>
                                <MenuItemNode
                                    name="manifests"
                                    to="/manifests"
                                    icon={<manifests.icon />}
                                    onClick={onMenuClick}
                                    label="Manifests"
                                />
                                <MenuItemNode
                                    name="docker_tags"
                                    to="/docker_tags"
                                    icon={<docker_tags.icon />}
                                    onClick={onMenuClick}
                                    label="Docker Tags"
                                />
                            </NavigationMenu>
                        </Box>
                    </Box>
                </CardContent>
            </MenuItemCategory>
            <MenuItemCategory
                name="catalogs"
                icon={<catalogs.icon />}
                onClick={onMenuClick}
                label="Catalogs"
            >
                <CardContent>
                    <Box display="flex" justifyContent="space-between">
                        <Box marginRight={2}>
                            <NavigationMenu>
                                <MenuItemNode
                                    name="instrument_group"
                                    to="/instrument_group"
                                    icon={<instrument_group.icon />}
                                    onClick={onMenuClick}
                                    label="Instrument groups"
                                />
                                <MenuItemNode
                                    name="calendar_events"
                                    to="/calendar_events"
                                    icon={<calendar_events.icon />}
                                    onClick={onMenuClick}
                                    label="Calendar events"
                                />
                                <MenuItemNode
                                    name="market_data_schedule"
                                    to="/market_data_schedule"
                                    icon={<market_data_schedule.icon />}
                                    onClick={onMenuClick}
                                    label="Market data schedule"
                                />
                            </NavigationMenu>
                        </Box>
                    </Box>
                </CardContent>
            </MenuItemCategory>
            <MenuItemCategory
                name="pl_emergency_brake"
                onClick={onMenuClick}
                label="Emergency brake"
                to="/pl_emergency_brake"
                icon={<pl_emergency_brake.icon />}
            />
            <MenuItemCategory
                name="dagsterQuotes"
                onClick={onMenuClick}
                label="Dagster Quotes"
                to="/dagster_quotes"
                icon={<dagsterQuotes.icon />}
            />
        </MultiLevelMenu>
    );
};
