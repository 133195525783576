import React, { Fragment } from 'react';
import {
    DateTimeInput,
    SelectInput,
    SimpleForm,
    TextInput,
    required,
    NumberInput,
    FormDataConsumer,
    Edit,
} from 'react-admin';
import { trimSpaces } from './transformers';

const ASSET_TYPE_BROKERS = ['saxo', 'moex', 'binance', 'cme', 'exante'];

export const HistoryFetcherInstrumentEdit = () => (
    <Edit
        title="Create a backtest configuration"
        transform={trimSpaces}
        mutationMode="pessimistic"
    >
        <SimpleForm>
            <TextInput source="name" validate={required()} />
            <NumberInput source="priority" validate={required()} />
            <TextInput label="Broker" source="broker" disabled />
            <FormDataConsumer>
                {({ formData }) =>
                    ASSET_TYPE_BROKERS.includes(formData.broker) && (
                        <Fragment>
                            <TextInput
                                label="Asset type"
                                source="asset_type"
                                disabled
                            />
                        </Fragment>
                    )
                }
            </FormDataConsumer>
            <FormDataConsumer>
                {({ formData }) =>
                    formData.broker === 'trading_view' && (
                        <Fragment>
                            <TextInput label="Exchange" source="exchange" />
                        </Fragment>
                    )
                }
            </FormDataConsumer>
            <SelectInput
                label="Status"
                source="status"
                choices={[
                    { id: 'running', name: 'Running' },
                    { id: 'paused', name: 'Paused' },
                ]}
                validate={required()}
            />
            <SelectInput
                label="Tick type"
                source="tick_type"
                choices={[
                    { id: 'trades', name: 'Trades' },
                    { id: 'quotes', name: 'Quotes' },
                ]}
                validate={required()}
            />
            <DateTimeInput
                label="Start Time"
                source="load_from"
                validate={required()}
            />
            <DateTimeInput label="End Time" source="load_to" />
        </SimpleForm>
    </Edit>
);
